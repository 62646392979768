<template>
    <v-card class="mt-4">
        <v-card-title>
            FECHAMENTOS/ADIANTAMENTOS
        </v-card-title>
        <v-card-text>
            <!-- Linha 1  -->
            <v-row>
                <v-col class="col-1 mx-2">
                </v-col>
                <v-col class="col-7 mx-2">
                    <v-row class="align-center">
                        <v-divider style=" border-width: 1px"></v-divider>
                        <span class="text-h6 mx-4">DIÁRIO</span>
                        <v-divider style=" border-width: 1px"></v-divider>
                    </v-row>
                </v-col>
                <v-col class="mx-2">
                    <v-row class="align-center">
                        <v-divider style=" border-width: 1px"></v-divider>
                        <span class="text-h6 mx-4">ACUMULADO</span>
                        <v-divider style=" border-width: 1px"></v-divider>
                    </v-row>
                </v-col>
            </v-row>
            <!-- Linha 2  -->
            <v-row>
                <v-col class="col-1">
                    <v-row class="align-center justify-space-around" style="border-bottom: 2px solid #E8E7EA;">
                        <span class="col text-center subtitle-1 font-weight-bold">PDV</span>
                    </v-row>
                </v-col>
                <v-col class="col-7">
                    <v-row class="align-center justify-space-around" style="border-bottom: 2px solid #E8E7EA;">
                        <span class="col text-center subtitle-1 font-weight-bold">Bruto</span>
                        <span class="col text-centersubtitle-1 font-weight-bold">Tx Serv</span>
                        <span class="col text-center subtitle-1 font-weight-bold">Tx ISS</span>
                        <span class="col text-center subtitle-1 font-weight-bold">Estornos</span>
                        <span class="col text-center subtitle-1 font-weight-bold">Qtde</span>
                        <span class="col text-center subtitle-1 font-weight-bold">Pool</span>
                        <span class="col text-center subtitle-1 font-weight-bold">Prop</span>
                        <span class="col text-center subtitle-1 font-weight-bold">Líquido</span>
                        <span class="col text-center subtitle-1 font-weight-bold">%</span>
                    </v-row>
                </v-col>
                <v-col>
                    <v-row class="align-center justify-space-around" style="border-bottom: 2px solid #E8E7EA;">
                        <span class="col text-center subtitle-1 font-weight-bold">Pool</span>
                        <span class="col text-center subtitle-1 font-weight-bold">Prop</span>
                        <span class="col text-center subtitle-1 font-weight-bold">Acumulado</span>
                        <span class="col text-center subtitle-1 font-weight-bold">%</span>
                    </v-row>
                </v-col>
            </v-row>

            <!-- Linha loop -->
            <v-row class="align-end" v-for="(item, key) in rds.payment" :key="key" v-if="item.pool_accumulated!=0">
                <v-col class="col-1">
                    <v-row class="align-center" style="border-bottom: 2px solid #E8E7EA;">
                        <span class="col text-center subtitle-2">{{ item.title }}</span>
                    </v-row>
                </v-col>
                <v-col class="col-7 align-center">
                    <v-row  style="border-bottom: 2px solid #E8E7EA;">
                        <span class="col text-center subtitle-2 ">{{ formatMoney(item.gross) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(item.txServ) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(item.txISS) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(item.reversals) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(item.qtde) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(item.pool) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(item.prop) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(item.liq) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(item.diary_percent) }}</span>
                    </v-row>
                </v-col>
                <v-col class="align-center">
                    <v-row class="justify-space-around" style="border-bottom: 2px solid #E8E7EA;">
                        <span class="col text-center subtitle-2 ">{{ formatMoney(item.pool_accumulated) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(item.prop_accumulade) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(item.dailies_accumulated) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(item.accumulated_percent) }}</span>
                    </v-row>
                </v-col>
            </v-row>

            <!-- Linha - Total  -->
            <v-row>
                <v-col class="col-1">
                    <v-row class="align-end justify-center">
                        <span class="col text-center subtitle-2 font-weight-bold">TOTAL</span>
                    </v-row>
                </v-col>
                <v-col class="col-7">
                    <v-row class="align-center">
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.payment_total.gross) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.payment_total.txServ) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.payment_total.txISS) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.payment_total.reversals) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.payment_total.qtde) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.payment_total.pool) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.payment_total.prop) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.payment_total.liq) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.payment_total.diary_percent)
                            }}</span>
                    </v-row>
                </v-col>
                <v-col>
                    <v-row class="align-center">
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.payment_total.pool_accumulated)
                            }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.payment_total.prop_accumulade)
                            }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.payment_total.dailies_accumulated)
                            }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.payment_total.accumulated_percent)
                            }}</span>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>

</template>

<script>


export default {
    components: {

    },

    props: {
        rds: {
            type: Object,
            required: true
        }
    },
    data: () => ({



    }),

    computed: {},
    watch: {},

    created() {

    },

    methods: {


        formatMoney(value) {
            if (value != null) {
                return value.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })
            }
        },


    }


}



</script>