<template>
    <v-card class="mt-4">
        <v-card-title>
            ESTATÍSTICAS
        </v-card-title>
        <v-card-text>
            <!-- Linha 1  -->
            <v-row>
                <v-col class="col-5 mx-2">
                </v-col>
                <v-col class="mx-2">
                    <v-row class="align-center">
                        <v-divider style=" border-width: 1px"></v-divider>
                        <span class="text-h6 mx-4">DIÁRIO</span>
                        <v-divider style=" border-width: 1px"></v-divider>
                    </v-row>
                </v-col>
                <v-col class="mx-2">
                    <v-row class="align-center">
                        <v-divider style=" border-width: 1px"></v-divider>
                        <span class="text-h6 mx-4">ACUMULADO</span>
                        <v-divider style=" border-width: 1px"></v-divider>
                    </v-row>
                </v-col>
            </v-row>
            <!-- Linha 2  -->
            <v-row>
                <v-col class="col-5">
                    <v-row class="align-center justify-space-around">
                    </v-row>
                </v-col>
                <v-col>
                    <v-row class="align-center justify-space-around" style="border-bottom: 2px solid #E8E7EA;">
                        <span class="col text-center subtitle-1 font-weight-bold">Valor Dia</span>
                        <span class="col text-center subtitle-1 font-weight-bold">%</span>

                    </v-row>
                </v-col>
                <v-col>
                    <v-row class="align-center justify-space-around" style="border-bottom: 2px solid #E8E7EA;">
                        <span class="col text-center subtitle-1 font-weight-bold">Valor acumulado</span>
                        <span class="col text-center subtitle-1 font-weight-bold">%</span>
                    </v-row>
                </v-col>
            </v-row>
            <!-- Linha loop -->
            <v-row v-for="(item, key) in rds.statistic" :key="key">
                <v-col class="col-5">
                    <v-row class="align-center justify-space-around" style="border-bottom: 2px solid #E8E7EA;">
                        <span class="col text-left subtitle-1 font-weight-bold" :style="(item.menu==2)?'text-indent: 2em;':''">{{ item.title }}</span>
                    </v-row>
                </v-col>
                <v-col>
                    <v-row class="align-center justify-space-around" style="border-bottom: 2px solid #E8E7EA;">
                        <span class="col text-center subtitle-1">{{ formatMoney(item.diary) }}</span>
                        <span class="col text-center subtitle-1 ">{{ formatMoney(item.diary_percent) }}</span>

                    </v-row>
                </v-col>
                <v-col>
                    <v-row class="align-center justify-space-around" style="border-bottom: 2px solid #E8E7EA;">
                        <span class="col text-center subtitle-1">{{ formatMoney(item.accumulated) }}</span>
                        <span class="col text-center subtitle-1">{{ formatMoney(item.accumulated_percent) }}</span>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>

</template>

<script>
import menu from '@/navigation/vertical';


export default {
    components: {

    },

    props: {
        rds: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        current_rds: {},

    }),

    computed: {},
    watch: {

    },

    created() {

    },

    methods: {

        formatMoney(value) {
            if (value != null) {
                return value.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })
            }
        },


    }


}



</script>