<template>
    <v-card class="mt-2">
        <v-card-text>
            <!-- Linha - Total  -->
            <v-row>
                <v-col class="col-1">
                    <v-row class="align-end justify-center">
                        <span class="col text-center subtitle-2 font-weight-bold">Total Geral</span>
                    </v-row>
                </v-col>
                <v-col class="col-7">
                    <v-row class="align-center">
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.total.gross) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.total.txServ) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.total.txISS) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.total.reversals) }}</span>
                        <span class="col text-centr subtitle-2 ">{{ formatMoney(rds.total.qtde) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.total.pool) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.total.prop) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.total.liq) }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.total.diary_percent)
                            }}</span>
                    </v-row>
                </v-col>
                <v-col>
                    <v-row class="align-center">
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.total.pool_accumulated)
                            }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.total.prop_accumulade)
                            }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.total.dailies_accumulated)
                            }}</span>
                        <span class="col text-center subtitle-2 ">{{ formatMoney(rds.total.accumulated_percent)
                            }}</span>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>

</template>

<script>


export default {
    components: {

    },

    props: {
        rds: {
            type: Object,
            required: true
        }
    },
    data: () => ({



    }),

    computed: {},
    watch: {},

    created() {

    },

    methods: {


        formatMoney(value) {
            if (value != null) {
                return value.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })
            }
        },


    }


}



</script>